import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { HStack } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Image from 'next/image';
import classNames from 'classnames';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import { isSet } from '@/utils/formatChecker';

interface SplitSwiperCard {
  img: string;
  city: string;
  district: string;
  content: string;
  customer: string;
  spotlights: Array<{
    label: string;
    content: string;
  }>;
  footer?: string;
}

const SplitSwiperSection: React.FC<{ cards: Array<SplitSwiperCard> }> = ({ cards }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  return (
    <div className={'-mx-4 sm:mx-0 p-4 sm:p-0 w-screen sm:w-auto bg-black'}>
      <div className={classNames('relative', 'px-0 pb-0 sm:p-8 sm:px-8 md:pb-6', 'bg-white md:bg-black')}>
        <Swiper autoHeight={false} loop className={'w-full'} slidesPerView={1} onSwiper={setSwiper}>
          {cards.map(({ img, city, district, content, customer, spotlights, footer }, index) => (
            <SwiperSlide
              key={index}
              className={classNames(
                'relative flex',
                'flex-col md:flex-row',
                'items-center md:justify-between md:flex-nowrap'
              )}
              style={{ height: 'auto' }}
            >
              <div
                className={classNames(
                  'relative',
                  'w-full md:w-[calc(50%-32px)]',
                  'max-w-[29.5rem] md:max-w-[unset]',
                  'z-0'
                )}
              >
                <Image src={img} layout={'responsive'} width={3} height={2} />
              </div>
              <div
                className={classNames(
                  // 'md:absolute right-0 bottom-0 z-10',
                  'md:relative z-10',
                  'px-6 sm:px-8 p-4 sm:p-8 pb-[4.5rem] md:pb-22',
                  'md:w-[calc(50%+((100%-(11px*32))/12)+32px)]',
                  'max-w-[29.5rem] md:max-w-[unset]',
                  'md:min-h-full',
                  'bg-white'
                )}
              >
                <p className={'mb-2 sm:mb-4 text-lg text-gray700'}>
                  {city}｜{district}
                </p>
                <HStack
                  spacing={{
                    base: 4,
                    sm: 4,
                    md: 8,
                  }}
                  className={'mb-2 sm:mb-4'}
                >
                  {spotlights.map(({ label, content }) => (
                    <div key={label}>
                      <p className={'text-gold text-[1.5rem] sm:text-[2rem]'}>{content}</p>
                      <p className={'text-gray700 text-xs sm:text-base'}>{label}</p>
                    </div>
                  ))}
                </HStack>
                <p className={'mb-2 sm:mb-4 py-2 text-gray700'}>{content}</p>
                <p className={'text-sm text-gray700'}>－ {customer}</p>
                <ConditionalFragment condition={isSet(footer)}>
                  <p className={'mt-2 sm:mt-4 text-xs text-gray500'}>{footer}</p>
                </ConditionalFragment>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={classNames(
            'flex md:block',
            'absolute bottom-4 md:bottom-14 right-1/2 md:right-16',
            'transform translate-x-1/2 md:transform-none z-20',
            'px-6 md:px-0',
            'max-w-[29.5rem] md:max-w-[unset]',
            'w-full md:w-auto'
          )}
        >
          <button
            type={'button'}
            className={'flex-1 w-40 h-10 border border-r-0 border-solid border-black'}
            onClick={() => swiper?.slidePrev()}
          >
            <ArrowBackIcon fontSize={24} />
          </button>
          <button
            type={'button'}
            className={'flex-1 w-40 h-10 border border-solid border-black'}
            onClick={() => swiper?.slideNext()}
          >
            <ArrowForwardIcon fontSize={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SplitSwiperSection;
